<template>
  <b-modal
    id="modal-spinwin-event-winner"
    ref="modal-spinwin-event-winner"
    :hide-footer="true"
    modal-class="custom-modal1"
    @shown="onShown"
    @hidden="onClose"
  >
    <div class="event-spin-container">
      <AnimationEventSpinWin
        :loop="true"
        :run="true"
        animation-step="win"
        @done="animReady = true"
      ></AnimationEventSpinWin>
    </div>
    <Spinner
      v-if="!animReady"
      size="medium"
      context="Loading Event..."
      classes="dead-center"
    ></Spinner>
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template v-if="animReady" slot="default">
      <div class="bg1"></div>
      <div class="bg2"></div>

      <div class="headline-container to-center-abs">
        <div class="headline1 golden-gradient-text text-uppercase big">
          <template v-if="winners.length > 1">{{
            $t("events.spin.winners.headline_1a")
          }}</template>
          <template v-else-if="winners.length === 1">{{
            $t("events.spin.winners.headline_1a_singular")
          }}</template>
        </div>
        <div class="headline2 golden-gradient-text text-uppercase">
          <template v-if="winners.length > 1">
            {{
              $t("events.spin.winners.headline_1b_new", {
                0: getAmazonVoucherValueByEvent(eventData) + "€",
              })
            }}</template
          >

          <template v-if="winners.length === 1">
            {{
              $t("events.spin.winners.headline_1b_new_singular", {
                0: getAmazonVoucherValueByEvent(eventData) + "€",
              })
            }}</template
          >
        </div>
      </div>

      <div class="winner-bg to-center-abs"></div>
      <div class="winner-list to-center-abs">
        <div
          v-for="winner in winners"
          :key="winner.id"
          class="winner clickable"
          @click="fetchPublicUser(winner.user_id)"
        >
          {{ winner.user.name }}
        </div>
      </div>

      <div class="mail-info">
        <p class="golden-gradient-text">
          {{ $t("events.battle.winners.mailtext") }}
        </p>
        <template v-if="userIsWinner">
          <p class="golden-gradient-text">
            {{ $t("events.battle.winners.mailtext_addon") }}
          </p>
          <button class="ok-button-green" @click="handleSupportClick">
            {{ $t("support.contact.tab_title") }}
          </button></template
        >
      </div>

      <div class="foot-note">
        {{ $t("events.battle.winners.raffle.disclaimer") }}
      </div>

      <div class="voucher"></div>
    </template>
  </b-modal>
</template>

<script>
import Spinner from "@/components/animation/animation-spinner.vue";
import AnimationEventSpinWin from "@/components/animation/animation-spine-event-spinwin.vue";
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
import eventPopups from "@/mixins/eventPopups";

export default {
  name: "ModalSpinWinEventWinner",
  components: {
    AnimationEventSpinWin,
    Spinner,
  },

  mixins: [getPublicUserInfos, eventPopups],

  data() {
    return {
      animReady: false,
    };
  },
  mounted() {
    this.$bvModal.show("modal-spinwin-event-winner");
  },

  methods: {
    onShown() {
      this.$store.commit("events/setShowEventWinner", new Date());
    },
    onClose() {
      this.$store.commit("popups/setNextPipelineQueueStep");
      this.animReady = false;
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1200px;
$modal-height: 715px;

#modal-spinwin-event-winner {
  .canvas-container {
    left: -120px;
    top: -50px;
  }

  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    padding-top: 50px;
    left: 25px;

    @media (max-height: 770px), (max-width: 1250px) {
      transform: scale(0.9);
      top: -40px;
      margin-bottom: -10%;
    }
    @media (max-height: 690px), (max-width: 1100px) {
      transform: scale(0.8);
      top: -120px;
      margin-bottom: -20%;
    }

    @media (max-width: 1250px) {
      left: -100px;
    }
  }

  .modal-header {
    margin-top: 0;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 0;
    right: 0;

    .close-button {
      right: 60px;
      top: 60px;
    }
  }

  .modal-body {
    margin-top: 0 !important;
    width: $modal-width;
    height: $modal-height;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
  }

  .golden-gradient-text {
    font-family: Ubuntu-Bold;
    font-size: 32px;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      #f7ffff,
      #ffee51 58%,
      #8b5a00
    );
    background-image: linear-gradient(to bottom, #f7ffff, #ffee51 58%, #8b5a00);
    filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.7)) drop-shadow(0 0 8px black);
    line-height: 1;

    &.big {
      font-size: 72px;
    }

    &.big2 {
      font-size: 40px;
    }
  }

  .headline-container {
    top: 235px;
    z-index: 99;

    .headline1 {
      font-family: Ubuntu-Bold;
      line-height: 1.2;
    }

    .headline2 {
      font-family: Ubuntu-Bold;
      line-height: 1.5;
    }

    .headline3 {
      font-family: Ubuntu-Bold;
    }
  }

  .winner-bg {
    width: 723px;
    height: 377px;
    background: url(~@/assets/img/events/winner-display-bg.png) bottom left/100%
      100% no-repeat;
    top: 220px;
    left: -20px;
    z-index: 1;
  }

  .winner-list {
    position: absolute;
    top: 380px;
    z-index: 99;
    line-height: 1.9;

    .winner {
      font-size: 36px;
      color: white;
      font-family: Ubuntu-Bold;
    }
  }

  .bg1 {
    margin-top: -10px;

    width: $modal-width;
    height: $modal-height;
    background: url(~@/assets/img/events/spin-win-bg-main.png) bottom left/50%
      100% no-repeat;

    position: absolute;
    left: 1px;
    top: 0;
  }

  .bg2 {
    margin-top: -10px;
    width: $modal-width;
    height: $modal-height;
    background: url(~@/assets/img/events/spin-win-bg-main.png) bottom left/50%
      100% no-repeat;

    position: absolute;
    right: 1px;
    top: 0;
    transform: scaleX(-1);
  }

  .event-spin-container {
    margin-top: -50px;
  }

  .mail-info {
    position: absolute;
    top: 625px;
    width: 100%;

    z-index: 99;
    p {
      font-size: 40px;
      font-family: Ubuntu-Bold;
      line-height: 1.3;
      text-align: center;
      &:last-of-type {
        font-size: 20px;
      }
    }
  }

  .foot-note {
    font-size: 18px;
    text-align: left;
    width: 290px;
    font-family: Ubuntu;
    color: #8e8e8e;
    position: absolute;
    bottom: 65px;
    left: -60px;
    line-height: 1.3;
    z-index: 99;
  }

  .voucher {
    width: 356px;
    height: 346px;
    position: absolute;
    top: 345px;
    right: 100px;
    z-index: 99;
  }
}
</style>

<style scoped lang="scss">
$modal-width: 1200px;
$modal-height: 688px;
#modal-spinwin-event-winner {
  .content {
    height: $modal-height;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    &.modal-content {
      width: $modal-width;
    }
  }
}
</style>
