<template>
  <div
    id="canvas-container-animation-event-spinwin"
    ref="canvascontainer"
    class="canvas-container-animation-event-spinwin"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";

export default {
  name: "AnimationEventSpinWin",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    run: {
      type: Boolean,
      default: true,
    },
    animationStep: {
      type: String,
      default: "main",
    },
  },
  data() {
    return {
      intervalId: null,
      unsubscribeEvents: [],
      destroyApp: false,
    };
  },

  watch: {
    run: function (newVal) {
      if (newVal) {
        this.destroyApp = true;
        this.play();
      }
    },
    animationStep: function (newVal) {
      if (newVal) {
        this.destroyApp = true;
        this.play();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);

    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },
  mounted: function () {
    this.play();
  },

  methods: {
    play: function () {
      const assets = [
        ["spinwin", "/assets/spines/events/spinwin/spinwin-animation.json?v=2"],
      ];
      const options = {
        width: 900,
        height: 620.34,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-event-spinwin";

      //ticker
      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);

          // set the position
          spine.x = app.screen.width / 2;
          spine.y = app.screen.height - 210;

          //set scale
          spine.scale.set(0.94);

          //add spine to stage
          app.stage.addChild(spine);

          //run animation
          let animation = this.getAnimationByName(data, this.animationStep);
          spine.state.setAnimation(0, animation.name);

          //show first frame when not running
          if (!this.run) {
            spine.state.getCurrent(0).animationEnd = 0;
            return;
          }

          //set animation end
          spine.state.getCurrent(0).animationEnd = animation.duration;

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }
        });
      };

      app.loader.load(setup);

      app.loader.onComplete.add(() => {
        this.$emit("done");
      });
    },
  },
};
</script>

<style lang="scss">
.canvas-container-animation-event-spinwin {
  pointer-events: none;
}
#canvas-animation-event-spinwin {
  position: relative;
  z-index: 2;
}
</style>
